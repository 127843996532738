import { useTranslation } from 'react-i18next'

import useLocalizePathname from '~/i18n/use-localize-pathname'
import { Hero, HeroImage, Button, ButtonVariant } from '~/tenset-components'
import { page404Illustration } from '~/assets/images'
import { Namespace } from '~/i18n'

export const handle = {
  i18n: [Namespace.COMMON],
}

export default function Error404() {
  const { t } = useTranslation([Namespace.COMMON])
  const localizePathname = useLocalizePathname()

  return (
    <section className="container place-items-center md:mb-16">
      <Hero
        title={t('404-page.title')}
        description={t('404-page.description')}
        rightContent={
          <HeroImage src={page404Illustration} alt={t('404-page.title')} />
        }
        leftContent={
          <div className="flex flex-col md:flex-row">
            <Button to={localizePathname('/')} variant={ButtonVariant.Primary}>
              {t('404-page.back-to-home')}
            </Button>
          </div>
        }
      />
    </section>
  )
}
